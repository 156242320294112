import React from "react";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";

const Preview404 = () => (
  <Grid container alignItems="center" direction="column">
    <Typography variant="h3">Not Found</Typography>
  </Grid>
);
export default Preview404;
